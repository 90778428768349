import * as React from 'react'
import { graphql } from 'gatsby'
import { SEO } from '../../components/seo'
import {
  LandingHero,
  FeaturedList,
  EventsSection,
  Collaborators,
  EventsTable,
  EventPhotosGrid,
} from '../../components/business-impact'
import { useAnimatedScrollTo } from '../../hooks'

interface Props {
  data: {
    dfbi: any
  }
}

const noTrailingSlash = s => (s.slice(-1) === '/' ? s.slice(0, -1) : s)

export function Page({ data }: Props) {
  const [dfbi] = data.dfbi.nodes
  const social = noTrailingSlash(data.site.meta.url) + data.social.publicURL
  const image = data.header.childImageSharp
  const animateScrollToCaseStudies = useAnimatedScrollTo('case-studies')
  const animateScrollToNewsletter = useAnimatedScrollTo('newsletter')
  return (
    <>
      <SEO
        image={social}
        title="Design For Business Impact"
        description="Designers need to understand and communicate how their work impacts the success of their business. By open-sourcing case studies and actionable tactics from some of the best in the business, we aim to help leaders effectively demonstrate the business impact of design. Join us in this movement to realize the potential of design as a strategic business partner."
      />
      <LandingHero
        title={dfbi.title}
        description={dfbi.description.description}
        image={{
          ...image,
          alt: 'Dropbox, Gusto, Pinterest, Slack, Twitter and Zendesk logos',
        }}
        onClickLogos={animateScrollToCaseStudies}
      />
      <FeaturedList items={dfbi.caseStudies} />
      <EventsSection
        heading={dfbi.eventsHeading}
        description={dfbi.eventsDescription.eventsDescription}
        table={() => <EventsTable events={dfbi.events} />}
        photos={() => <EventPhotosGrid photos={dfbi.eventPhotos} />}
        onClickMarquee={animateScrollToNewsletter}
      />
      <Collaborators
        heading="Thank You to Our Collaborators"
        logos={dfbi.collaborators}
      />
    </>
  )
}

export const query = graphql`
  fragment DFBICaseStudyListCardFields on ContentfulTemplateCaseStudy {
    id
    title
    slug
    companyName
    contentTags
    listImage {
      description
      fluid(maxWidth: 920, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    listDescription {
      listDescription
    }
  }

  fragment DFBIEventFields on ContentfulTypeEvent {
    title
    location
    dateStart
    dateEnd
    ctaText
    ctaLink
    buttonStyle
  }

  fragment DFBISharedFields on ContentfulSettingsDesignForBusinessImpact {
    newsletterSectionHeading
  }

  fragment DFBILandingPageFields on ContentfulSettingsDesignForBusinessImpact {
    title
    tagline
    description {
      description
    }
    headerImage {
      description
      fluid(maxWidth: 840, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    eventsHeading
    eventsDescription {
      eventsDescription
    }
    caseStudies {
      ...DFBICaseStudyListCardFields
    }
    events {
      ...DFBIEventFields
    }
    eventPhotos {
      title
      description
      fluid(maxWidth: 1080, quality: 75) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    collaborators {
      text
      location
      media {
        description
        file {
          url
        }
      }
    }
  }

  query DFBILandingPage {
    site {
      meta: siteMetadata {
        url
      }
    }
    dfbi: allContentfulSettingsDesignForBusinessImpact(limit: 1) {
      nodes {
        ...DFBISharedFields
        ...DFBILandingPageFields
      }
    }
    social: file(relativePath: { eq: "dfbi-social.png" }) {
      publicURL
    }

    header: file(relativePath: { eq: "dfbi-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Page
