import React from 'react'
import cx from 'classnames'
import anime from 'animejs'
import useEventListener from '@use-it/event-listener'
import { CoverImage } from '../image'
import styles from './event-photos-grid.module.scss'
import { range } from '../../utils/lerp'

const VELOCITY = [1, 1.25, 1, 1.75, 1.125, 1.5]

export default function EventPhotosGrid({ photos = [] }) {
  return (
    <div className={styles.section__limit}>
      <div className={styles.section}>
        {photos.map((p, idx) => (
          <EventPhoto
            key={p.fluid.src}
            fluid={p.fluid}
            caption={p.title}
            alt={p.description}
            velocity={VELOCITY[idx % VELOCITY.length]}
          />
        ))}
      </div>
    </div>
  )
}

function viewport(el) {
  const scroll = window ? window.pageYOffset : 0
  const { top, height } = el
    ? el.getBoundingClientRect()
    : { top: 0, height: 0 }
  const vh = window ? window.innerHeight : 0
  return { start: scroll + top - vh, end: scroll + top + height }
}

function useParallax({ velocity = 1 } = {}) {
  const ref = React.useRef()
  const timeline = React.useRef()
  const [scroll, setScroll] = React.useState(null)
  const [start, setStart] = React.useState(null)
  const [end, setEnd] = React.useState(null)
  useEventListener('resize', () => {
    const v = viewport(ref.current)
    setStart(v.start)
    setEnd(v.end)
  })
  useEventListener('scroll', () => setScroll(window.pageYOffset))
  React.useEffect(() => {
    const v = viewport(ref.current)
    setStart(v.start)
    setEnd(v.end)
    timeline.current = anime({
      targets: ref.current,
      translateY: [100 * velocity, -100 * velocity],
      easing: 'linear',
      autoplay: false,
    })
  }, [ref])
  React.useEffect(() => {
    const tl = timeline.current
    if (tl && tl.duration && tl.seek) {
      const progress = range(start, end, 0, tl.duration, scroll)
      tl.seek(progress)
    }
  }, [scroll, start, end])

  return { ref }
}

function EventPhoto({ caption, alt, fluid, velocity }) {
  const { ref } = useParallax({ velocity })
  return (
    <figure ref={ref} className={cx(styles.item, styles.nth)}>
      <CoverImage className={styles.item__image} fluid={fluid} alt={alt} />
      <figcaption className={styles.item__caption}>
        <span>{caption}</span>
      </figcaption>
    </figure>
  )
}
