import React from 'react'
import styles from './landing-hero.module.scss'
import { CoverImage } from '../image'
import cx from 'classnames'
import { ScrollArrow } from '.'

const [CURSOR_SIZE] = [160]

function useCustomCursor(containerRef) {
  const ref = React.useRef()
  const [x, setX] = React.useState(0)
  const [y, setY] = React.useState(0)
  React.useEffect(() => {
    function onUpdate(e) {
      // const h = containerRef.current.innerHeight
      setX(e.clientX - CURSOR_SIZE / 2)
      setY(e.clientY - CURSOR_SIZE / 2)
    }
    containerRef.current.addEventListener('mousemove', onUpdate)
    return () => containerRef.current.removeEventListener('mousemove', onUpdate)
  }, [ref, containerRef])
  return { ref, coords: { x, y } }
}

export default function LandingHero({
  title,
  description,
  onClickLogos,
  image,
}) {
  const container = React.useRef()
  const { ref: cursor, coords } = useCustomCursor(container)

  return (
    <section ref={container} className={styles.hero}>
      <div className="container container--grid">
        <div className="container__row position-relative">
          <div className={styles.hero__content}>
            <h1 className={styles.hero__title}>{title}</h1>
            <p className={styles.hero__description}>{description}</p>
          </div>
          <div
            onClick={onClickLogos}
            className={cx(styles.hero__image, 'child-my-0')}
          >
            <CoverImage ratio={946 / 1266} {...image} />
          </div>
        </div>
      </div>
      <div
        ref={cursor}
        style={{
          transform: `translate3d(${coords.x}px, ${coords.y}px, 0)`,
          display: coords.x === 0 && coords.y === 0 ? 'none' : undefined,
        }}
        className={styles.hero__cursor}
      >
        <ScrollArrow />
      </div>
    </section>
  )
}
