import React from 'react'
import cx from 'classnames'
import { Waypoint } from 'react-waypoint'
import { CaseStudyCard } from '../case-study'
import { FeatureLabel } from '../feature-label'
import { FeaturedHeading } from '.'
import styles from './featured-list.module.scss'

export default function FeaturedList({ id = 'case-studies', items }) {
  return (
    <section id={id} className={styles.section}>
      <div className="container container--grid">
        <div className="container__row">
          <div className={styles.section__grid}>
            <div className={styles.section__heading}>
              <FeaturedHeading />
            </div>
            <div className={styles.section__list}>
              <ul className={cx(styles.list, 'list-reset')}>
                {items.map((item, idx) => (
                  <Card key={item.title} idx={idx} item={item} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Card({ idx, item }) {
  const [visible, setVisible] = React.useState(false)
  return (
    <li
      key={item.slug}
      className={cx(styles.list__item, { [styles.reveal]: !visible })}
    >
      <Waypoint onEnter={() => setVisible(true)} />
      {idx !== 0 ? null : (
        <div className={styles.list__new}>
          <FeatureLabel border text="New Case Study" />
        </div>
      )}
      <CaseStudyCard
        image={{
          fluid: item.listImage.fluid,
          alt: item.listImage.description,
        }}
        title={item.title}
        company={item.companyName}
        description={item.listDescription.listDescription}
        tags={item.contentTags}
        url={`/business-impact/${item.slug}`}
      />
    </li>
  )
}
