import React from 'react'
import cx from 'classnames'
import styles from './collaborators.module.scss'
import { Link } from '../link'

export default function Collaborators({ heading, logos }) {
  return (
    <section className={styles.section}>
      <div className="container container--grid">
        <div className="container__row">
          <h2 className={styles.section__heading}>{heading}</h2>
          <ul className={cx(styles.grid, 'list-reset')}>
            {logos.map(logo => (
              <li key={logo.media.file.url} className={styles.grid__item}>
                <Link to={logo.location} className={styles.grid__item__link}>
                  <img src={logo.media.file.url} alt={logo.text} />
                </Link>
              </li>
            ))}
          </ul>
          <p className={cx(styles.section__cta, 'type-dfbi-para')}>
            Are you interested in collaborating in 2020? Email us at{' '}
            <a href="mailto:businessimpact@designerfund.com">
              businessimpact@designerfund.com
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}
