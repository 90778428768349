import React from 'react'
import cx from 'classnames'
import useEventListener from '@use-it/event-listener'
import styles from './events-section.module.scss'
import { EventsHeading, EventsMarquee } from '.'

function getRect(el) {
  return el ? el.getBoundingClientRect() : {}
}

function useWatchHeight() {
  const ref = React.useRef()
  const [height, setHeight] = React.useState('auto')
  useEventListener('resize', () => {
    const { height: h } = getRect(ref.current)
    setHeight(h)
  })
  React.useEffect(() => {
    const { height: h } = getRect(ref.current)
    setHeight(h)
  }, [ref])
  return { ref, height }
}

function shouldSetHeight() {
  return global && global.innerWidth >= 768
}

export default function EventsSection({
  heading,
  description,
  table,
  photos,
  id = 'events',
  onClickMarquee = undefined,
}) {
  const { ref, height } = useWatchHeight()
  const setHeight = shouldSetHeight()
  return (
    <section id={id} className={styles.section}>
      <div className="container container--grid">
        <div className="container__row">
          <hr className={styles.section__divider} />
        </div>
      </div>
      <div className="position-relative">
        <div className={styles.titleContainer}>
          <div
            className="container container--grid"
            style={{ height: setHeight ? height : 'auto' }} // safari :facepalm:
          >
            <div className="container__row">
              <div className={styles.section__grid}>
                <div className={styles.section__title}>
                  <EventsHeading />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div ref={ref} className="container container--grid">
            <div className="container__row">
              <div className={styles.section__grid}>
                <div className={styles.section__content}>
                  <header
                    className={cx(
                      styles.header,
                      'color-dark-navy',
                      'child-my-0'
                    )}
                  >
                    <h3 className={cx(styles.header__heading, 'type-p-xxl')}>
                      {heading}
                    </h3>
                    <p className={styles.header__description}>{description}</p>
                  </header>
                  <div className={styles.table}>{table()}</div>
                  <aside className={styles.photos}>{photos()}</aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer onClick={onClickMarquee}>
        <EventsMarquee />
      </footer>
    </section>
  )
}
