import React from 'react'
import cx from 'classnames'
import { DFBIButton } from '../button'
import { Link } from '../link'
import styles from './events-table.module.scss'

// date from server is in format 2020-01-31
const format = d => {
  // split off year and grab positional day / month values
  const [, day, month] = d.split('-')
  // knock off leading zero and return as `1/31`
  return `${parseInt(day)}/${parseInt(month)}`
}

export default function EventsTable({ events = [] }) {
  return (
    <div>
      <div className={cx(styles.headings, 'type-dfbi-label')}>
        <div className={styles.headings__date}>Dates</div>
        <div className={styles.headings__info}>Event Title &amp; Location</div>
        <div className={styles.headings__cta}>Join Us</div>
      </div>
      <div className={styles.table}>
        {events.map(
          ({
            title,
            dateStart,
            dateEnd,
            location,
            ctaLink,
            ctaText,
            buttonStyle,
          }) => (
            <div key={title} className={styles.row}>
              <div className={styles.row__date}>
                <span>{format(dateStart)}—</span>
                <span>{format(dateEnd)}</span>
              </div>
              <div className={styles.row__info}>
                <span>{title}</span>
                <span> — </span>
                <span>{location}</span>
              </div>
              <div className={styles.row__cta}>
                <DFBIButton
                  fill
                  appearance={
                    buttonStyle.toLowerCase() === 'secondary'
                      ? 'secondary'
                      : undefined
                  }
                  as={Link}
                  to={ctaLink}
                >
                  {ctaText}
                </DFBIButton>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}
